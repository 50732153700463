class ColorSwatch extends HTMLElement {
  constructor() {
    super();
    this.handle = this.getAttribute('handle');
    this.sectionId = this.getAttribute('section-id');
    this.variantRadios = document.querySelector('variant-radios');
    this.input = this.querySelector('input');

    this.addEventListener('change', this.onColorUpdate.bind(this));
  }

  onColorUpdate(event) {
    event.preventDefault();
    fetch(`/products/${this.handle}?section_id=${this.sectionId}`)
      .then((response) => response.text())
      .then((responseText) => {
        // Parse the HTML response
        const html = new DOMParser().parseFromString(responseText, 'text/html').querySelector('.js-product-main');

        // Update the DOM with the new content
        document.querySelector('.js-product-main').innerHTML = html.innerHTML;

        // Use requestAnimationFrame to ensure DOM is updated before proceeding
        requestAnimationFrame(() => {
          // After DOM update, find the variant components that need initialization
          const variantSelects = document.querySelector('variant-selects');
          const variantRadios = document.querySelector('variant-radios');

          // Re-check the current color swatch
          const currentSwatch = document.querySelector(`color-swatch[handle="${this.handle}"]`);
          if (currentSwatch && currentSwatch.querySelector('input')) {
            currentSwatch.querySelector('input').checked = true;
          }

          // Update URL
          window.history.replaceState({}, '', `/products/${this.handle}`);

          // Manually initialize variant components if they exist
          // This is better than relying on their existing event handlers
          if (variantSelects) {
            // Force initialization of variant data
            variantSelects.getVariantData();
            // Trigger a synthetic change event to initialize everything properly
            setTimeout(() => {
              const event = new Event('change', { bubbles: true });
              variantSelects.dispatchEvent(event);
            }, 50);
          }

          if (variantRadios) {
            // Force initialization of variant data
            variantRadios.getVariantData();
            // Trigger a synthetic change event to initialize everything properly
            setTimeout(() => {
              const event = new Event('change', { bubbles: true });
              variantRadios.dispatchEvent(event);
            }, 50);
          }

          // Dispatch a product:updated event after everything is initialized
          document.dispatchEvent(
            new CustomEvent('product:updated', {
              detail: {
                sectionId: this.sectionId,
                productId: this.dataset.productId
              },
              bubbles: true
            })
          );
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }
}

customElements.define('color-swatch', ColorSwatch);
